@import '../../../assets/scss/partials/vars';

.search-block{
    display: flex;
    align-items: center;
    margin-top: 16px;
    padding: 20px 12px;
    background-color: $aw-orange-dark;

    .input-wrap{
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        width: 80%;
        margin: 0;
        padding: 0px;
        background: $white;
        border-radius: 5px;

       
        i.aw {
            color: #490;
            font-size: 1.24rem;
            margin-left: 8px;

            @media(min-width: $screen-md){
                margin-left: 10px;
            }

        }
    
    
        input.search {
            display: block;
            padding: 3.27vw 1.64vw;
            border: 0;
            outline: none;
            color: $copy-grey;
            font-family: $GothamFont;
            font-size: 3.9vw;
            //letter-spacing: 0.02rem;

            @media(min-width: $screen-md){
                width: 80%;
                padding: 14px;
                font-size: 1rem;
            }
        }
        button.submit {
            display: block;
            margin-right: 12px;
            background: none;
            border: 0;
            outline: none;
            cursor: pointer;

            i.aw {
                display: block;
                color: rgba(75, 51, 39, 1);
            }
        }
        
    }
    .filter-btn-wrap{
        display: block;
        width: 25%;

        button.btn-filter{
            display: flex;
            align-items: center;
            margin-left: 12px;
            outline: none;
            border: 0;
            background-color: $aw-orange-dark;
            color: $white;
            font-family: $GothamFont;
            font-size: 0.9rem;
            cursor: pointer;

            i.aw {
                margin-left: 8px;
                font-size: 1.4rem;
                transition: transform 500ms ease, background-color 100ms ease, color 100ms ease;
            }

            &.active {
                i.aw { 
                    transform: rotate(45deg);
                }
            }

            @media(min-width: $screen-md){
                margin-left: 1.6vw;
                font-size: 1.1rem;
            }
        }

        &.open{
            button.btn-filter i.aw {
                transform: rotate(45deg);
            }
        }
    }
}

.restaurant-options {
    box-sizing: border-box;
    //display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 20px;
    height: 170px;
    background: $white;
    color: $aw-brown;
    opacity: 1;
    transition: all 0.35s linear;
   

    &.close {
        visibility: hidden;
        height: 0;
        opacity: 0;
        padding: 0 20px;
    }

    p {
        display: block;
        width: 100%;
        margin: 0 auto 20px;
        font-family: $GothamFont;
    }

    .input-wrap {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        height:80px;
        width: 95%;
        @media(min-width:950px) {
            width:100%;
        }

        label {
            display: flex;
            align-items: center;
            flex-basis:50%;
            font-weight: 600;

            input { 
                visibility: hidden;
                width: 0;
                
            }

            i.aw {
                height: 30px;
                width: 30px;
                margin-right: 6px;
            }
    
            i.aw.checked span::before {
                content: "\E907";
                display: block;
                height: 100%;
                position: absolute;
                width: 100%;
                font-size: 1.6rem;
            }

        }

    }

        
}