@import '../../../assets/scss/partials/vars';

.single-location-panel {
    z-index: 10;
    position: relative;
    top: 0;
    display: block;
    width: 100%;

    
    .title-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 1.64vw;
        background: $aw-orange;
        color: $white;
        font-family: $GothamFont;

        @media(min-width: $screen-md){
            padding: 24px 12px;
        }

        a.location-home {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            text-decoration: none;
            color: $white;
            font-family: $GothamFont;

            h4{
                overflow: hidden;
                position: relative;
                white-space: nowrap;
                color: $white;
                font-family: $GothamFont;
                font-size: 3.6vw;
                font-weight: 600;
                

                @media(min-width: $screen-md){
                    line-height: 1.6;
                    font-size: 1.05rem; 
                }
    
                i.aw.pin {
                    overflow: visible;
                    height: 4.4vw;
                    line-height: 0.7;
                    font-size: 6vw;

                    @media(min-width: $screen-md){
                        height: 22px;
                        font-size: 1.95rem;
                    }
                }
                span.fade {
                    z-index: 2;
                    position: absolute;
                    right: -10px;
                    top: 0;
                    display: block;
                    height: 2em;
                    width: 4em;
                    background: linear-gradient(90deg,rgba(255,98,24,0) 0,#ff6218);
                    content: "";
                    //opacity: .01;
                    transition: opacity 0ms linear;
                    @media(min-width:$screen-xl) {
                        right: -90px;
                    }
                }

            }

            .details {
                display: flex;
                align-items: center;
                //color: $aw-brown;
                font-family: $GothamFont;
                font-size: 3.2vw;
                font-weight: 400;

                @media(min-width: $screen-md){
                    font-size: 1.05rem;
                }

                span {
                    display: block;
                    margin: 0 2px;

                    &.distance {
                        white-space: nowrap;
                        font-family: $GothamFont;
                        font-weight: 400;
                        margin: 0 1.6vw;

                        @media(min-width: $screen-md){
                            margin: 0 0.4vw;
                        }
                    }
                    &.status {
                        font-family: $GothamFont;
                        font-weight: 600;
                    }
                    
                }

                i.aw.plus-inverted {
                    margin-bottom: 0;
                    font-size: 4vw;
                    line-height: 0.7;

                    &.open {
                        transform: rotate(45deg);
                    }

                    @media(min-width: $screen-md){
                        height: 24px;
                        font-size: 1.85rem;
                        line-height: 0.7;
                    }
                }

            }
        }

       

       
    }

    .location-info{
        display: block;
        background: $white;
        background: #f5f5f5;
     //   padding: 4vw 2.6vw;
     padding: 40px 2.6vw;
     //   padding-top:30px;
        color: $aw-brown;

        @media(min-width: $screen-md){
            padding: 30px 20px;
        }

        h4 {
            display: block;
            margin: 0 auto 22px;
            color: $aw-green;
            font-family: $GothamFont;
            font-size: 3.36vw;
            font-weight: 600;

            @media(min-width: $screen-md){
                font-size: 1.05rem;
            }
        }

        h5{
            color: $aw-brown;
            font-family: $GothamFont;
            font-size: 3.26vw;
            font-weight: 700;
            line-height: 1.6;

            @media(min-width: $screen-md){
                font-size: 0.95rem;
            }
        }
        p{
            //margin: 0 auto;
            color: $aw-brown;
            font-family: $GothamFont;
            font-size: 3.26vw;
            font-weight: 700;
            line-height: 1.6;

            @media(min-width: $screen-md){
                font-size: 0.95rem;
            }
        }

        a.get-directions{
            display: block;
            padding: 3.6vw 0;
            color: $aw-brown;
            font-family: $GothamFont;
            font-size: 3.6vw;
            text-decoration: none;
            outline: none;
            border: 0;

            @media(min-width: $screen-md){
                padding: 16px 0;
                font-size: 0.916rem;
            }
        }

        a.mobile-order{
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-around;
            max-width: 225px;
            margin: 8px 0 75px 0;
            padding: 10px 12px 10px 13px;
            background: $aw-orange;
            text-align: center;
            color: #fff;
            font-family: $GothamFont;
            font-size: 3.6vw;
            font-weight: 700;
            line-height: 1.5;
            text-decoration: none;
            border-radius: 20px;

            @media(min-width: $screen-md){
                margin: 2px 0 55px 0;
                font-size: 0.916rem;
            }

            &:lang(fr){
                max-width: 260px;
            }
        }

        hr {
            background-image: url('../../../assets/images/hr_tile.png');
            background-repeat: repeat-x;
            background-size: 3.5px 1.5px;
            border: none;
            height: 3px;
            margin: 2em 0;
        }
    }
}