@import '../../assets/scss/partials/vars';

img.aw-logo {
    display: block;
   // height: 32px;
    height: 9.9vw;
    width: auto;
    margin-right: 4px;

    @media(min-width: $screen-md){
        width: 116px;
        height: auto;
        margin-right: 14px;
    }


}