@import '../../../assets/scss/partials/vars';


.lang-toggle-wrap{
    display: inline-block;
    min-width: 50px;
    margin-left:10px;
}
.lang-toggle {
    // position: absolute;
    // right: 3vw;
    // top: 0;
    // padding-top: calc(2vw + 3px);
    font-family: $AWSansRegular;
    font-size: 3.2vw;

    @media(min-width: $screen-md){
        position: relative;
        top: 0;
        right: 0;
        padding-top: 0; 
        font-size: 0.9rem;
    }

    a {
        display: inline-block;
        outline: none;
        border: 0;
       // line-height: 1.4;
        color: $aw-brown;
        font-family: $AWSansRegular;
       // font-size: 0.9rem;
       // font-size: 0.65rem;
        font-weight: 400;
        text-decoration: none;

        &.active {
            font-weight: 700;
        }
        &:hover {
            color:$aw-orange;
        }
    }

   
}