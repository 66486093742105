@import '../../assets/scss/partials/vars';

.loading-wrap{
    // display: block;
    // height: 100px; 
    // width: 100px;
    // margin: 100px auto;

    display: flex;
    height: 50vh;
    width: 100%;
    justify-content: center;
    align-items: center;

    @media(min-width: $screen-md){
        height: 100vh;
    }

    //Spinner div
    > div {
        margin: 0 auto;
    }
}