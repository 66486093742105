@import '../../assets/scss/partials/vars';

.footer {
	padding-bottom: 4vw;
	background: $white;
	text-align: center;
	color: #d8d8d8;
	font-family: $AWSerifDisplay;

	@media(min-width: $screen-md){
		padding-bottom: 16px;
	}

	a {
		text-decoration: none;
	}

	div {
	 

		&.language-selector {
			margin: 16px 16px 24px;
			background-image: url("../../assets/images/hr_tile.png");
			background-position: 0 4vw;
			background-repeat: repeat-x;
			background-size: 3.5px 1.5px;
			color: #4b3327;

			@media(min-width: $screen-md){
				background-position: 0 18px;
			}
			

			> span {
				background-color: #fff;
				padding-left: 1em;
				padding-right: 1.5em;
			}

		
			i.aw {
				display: inline-block;
				margin-bottom: -0.35em;
				margin-right: 0.1em;
				color: #e0001a;
				font-size: 8vw;

				@media(min-width: $screen-md){
					font-size: 2.4rem;
				}
			}
		}

		.lang-toggle-wrap{
			position: relative;
			display: inline-block;
			margin-left: 4px;

			.lang-toggle{
				position: relative;
				font-size: 3.1vw;

				@media(min-width: $screen-md){
					font-size: 0.92rem;
				}

			}
		}

		

	}

	.links {
		li{
			display: inline-block;
			//line-height: 1.4;
		}
		a {
			display: inline-block;
			margin: 7px 0;
			padding-left: 0.3vw;
			padding-right: 0.3vw;
			color: #676767;
			
			font-family: $AWSerifRegular;
			font-size: 3.1vw;
			font-weight: 400;
			transition: color 100ms ease-in-out;

			@media(min-width: $screen-md){
				font-size: 0.9rem;
			}

			&:hover {
				color: $aw_orange;
			}
		}
	}


	.copyright {
		margin-top: 14px;
		color: #676767;
		font-family: $AWSansRegular;
		font-size: 0.84rem;
		
		@media(min-width: $screen-md){
			//margin-top: 36px;
			font-size: 0.84rem;
			max-width: 850px;
    		margin: 36px auto 0;
		}
	}

}
