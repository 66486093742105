@import '../../../../assets/scss/partials/vars';

.restaurant-location-contact {
    padding: 3vw 0 10vw;

    @media(min-width: $screen-md){
        padding: 0 0 50px;
    }

    h3 {
        margin: 8px 0px;
        color: $aw-brown;
        font-family: $GothamFont;
        font-size: 3.6vw;
        font-weight: 600;

        @media(min-width: $screen-md){
            padding: 0 0 15px;
            font-size: 1.05rem;
        }
    }

    p {
        display: flex;
        align-items: center;
        justify-content: space-between;

        a {
            color: $aw-brown;
            font-size: 3.6vw;
            font-weight: 400;
            text-decoration: none;

            @media(min-width: $screen-md){
                font-size: 1.05rem;
            }

        }

        a.apply-btn {
            padding-right: 20%;
        }

        i.aw.phone {
            font-size: 6vw;
            line-height: 1.2;

            @media(min-width: $screen-md) {
                font-size: 1.65rem;
                line-height: 1.4;
            }
        }
    }

}