@import '../../assets/scss/partials/vars';

$screen-switch: 851px;

.mobile-order-banner {
  font-family: $AWSerifDisplay;
  display: block;
  justify-content: space-between;
  align-items: center;
  margin: 25px 25px 10px 25px;
  padding: 45px 25px;
  border-radius: 25px;
  background-color: #f9f9f9;


  @media(min-width:768px) {
    padding: 45px 50px;
  }

  @media(min-width:$screen-switch) {
    border-radius: 15px;
    display: flex;
    margin: 50px 30px 10px 30px;
    padding: 45px 50px;
  }

  @media(min-width:1200px) {
    margin: 100px 100px 10px 100px;
    padding: 65px 70px;
  }

  @media(min-width:1600px) {
    padding: 65px 90px;
  }

  .app-icon {
    display: block;
    width: 55px;
    height: auto;
    margin: 0 auto;
    margin-bottom: 20px;
    @media (min-width: $screen-switch) {
      display: inline;
      vertical-align: middle;
      margin-bottom: 0;
      width: 75px;
      height: auto;
      &:lang(fr) {
        width:70px;
        height: auto;
      }
    }
  }

  .mobileapp-copy {
    text-align:center;
    margin: 0 auto;
    @media(min-width: $screen-switch) {
      display: inline-block;
      vertical-align: middle;
      margin-left:15px;
    }

    @media(min-width: 990px) {
      margin-left:25px;
    }

    
    @media(min-width: 1600px) {
      margin-left: 40px;
    }
    
    h3 {
      font-family: $AWSerifDisplay;
      font-size:1.3rem;
      font-weight:700;
      padding: 0;
      margin: 0;
      margin-bottom:10px;
      color: $aw_orange;
      &:lang(fr) {
      //  font-size: .8rem;
      }

      @media(min-width: 375px) {
        &:lang(fr) {
       //   font-size:1rem;
        }
      }

      @media(min-width:500px) {
        font-size: 1.7rem;
       &:lang(fr) {
        font-size:1.7rem;
      }
      }
     
      @media(min-width: $screen-switch) {
       // font-size: 1.3rem;
        margin-bottom: 0;
        text-align: left;
        // &:lang(fr) {
        //   font-size: 1rem;
        // }
      }

      @media(min-width: 990px) {
        font-size: 1.6rem;
        &:lang(fr) {
          font-size:1.6rem;
        }
      }
      @media(min-width: 1200px) {
        font-size: 1.6rem;
      
      }

      @media(min-width: 1350px) {
        font-size: 1.7rem;
       
      }
    
    }
    p {
      padding: 0;
      margin: 7px 0 0 0;
      font-family: $AWSerifRegular;
      font-size: 1rem;

      @media(min-width: 375px) {
        font-size:1.1rem;
      }

      @media(min-width:500px) {
        font-size:1.1rem;
        &:lang(fr) {
          font-size:1.1rem;
        }
      }

      @media(min-width: $screen-switch) {
        text-align: left;
        font-size:1.1rem;
        &:lang(fr) {
         // font-size:.85rem;
        }
      }

      @media(min-width: 900px) {
        &:lang(fr) {
         // font-size:.9rem;
        }
      }

      @media(min-width:990px) {
        font-size:1.1rem;
        &:lang(fr) {
          font-size:1.1rem;
        }
      }

      @media(min-width: 1200px) { 
     //   font-size: 1rem;
      }
    }
  }

  .mobileapp-btns {
    text-align: center;
    margin-top:45px;
   // font-size:0;
    @media(min-width:$screen-switch) {
      text-align: right;
      margin-top:0;
    }
  }

  .order-btn {
    padding: 13px 20px 13px 20px;
    background: #ff6218;
    background: $aw-orange;
    text-align: center;
    color: #fff;
    font-family: $AWSerifDisplay;
    font-size: 1.1rem;
    font-weight: 700;
    line-height: 1.5;
    text-decoration: none;
    border-radius: 25px;
    @media(min-width:$screen-switch) {
      font-size:1.3rem;
    }
    &:hover {
      background: $aw-orange-rollover;
    }
  }
  
  .app-btn {
    display:inline-block;
    max-width:46%;
    @media(min-width:850px) {
      max-width:100%;
    }
    img {
      display:inline-block;
      max-width:100%;
      width:130px;
      height: auto;
    
      @media(min-width:$screen-md) {
        max-width:100px;
        width:100%;
        height: auto;
      }

      @media(min-width:990px) {
        max-width:130px;
        width:100%;
        height: auto;
      }
      @media(min-width:1200px) {
        max-width:150px;
        width:100%;
        height: auto;
      }
    
    }
  }

  .google-btn {
    margin-left:10px;
    @media(min-width:1200px) {
      margin-left:20px;
    }
  }


  span.mobile-break{
		display: none;
		clear:both;
		@media (max-width: 850px){
			display: block;
		}
	}
}