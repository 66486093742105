@import '../../assets/scss/partials/vars';

.drawer {
    width: 100%;
    margin: 0 auto;
    background: $white;
    color: $aw-brown;
    font-family: $baseFont;
    border: 1px solid #F0F0F0;
    margin-bottom: -1px;

    .copy {
        font-size:1rem;
        line-height:1.5;
        max-width:90%;
        padding:40px 5%;
    }

    &:last-child{
        border-bottom: 1px solid #F0F0F0;
        margin-bottom:1px;
    }

    @media(min-width: $screen-sm){
     //   max-width: 90%;
    }

    @media(min-width: $screen-md){
        max-width: 912px;
    }

    .title-wrap{
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;
        padding: 20px 16px;
        color: $aw-brown;
        font-size:3.6vw;
        line-height:1.3;
        font-weight: 700;
        text-align: left;
        cursor: pointer;
        transition: all 0.25s linear;
        @media(min-width:$screen-md) {
            font-size:1.05rem;
        }

        &:hover {
            color: $aw-orange;
            i.aw {
                color:$aw-orange;
                transition: all 0.25s linear;
            }
        }

        h4{
            display: inline-block;
            margin-bottom: 0;
            font-family: $baseFont;
            font-size: 1.35rem;
            font-weight: 200;
            text-align: left;
        }

    }

    .first {
        display:none;
    }


    div.icon {
        display: inline-block;
        transition: all 0.35s linear;
        font-size: 1.4rem;
        font-size:5vw;
        margin-left:20px;
        @media(min-width:$screen-md) {
            font-size:1.6rem;
        }
    }


    // Drawer 'open' state
    &.open {
        .title-wrap {
            color: $aw-brown;
            border-bottom: none;
        }
        .icon{
            transform: rotate(45deg);
            transform-origin: center center;
        }
        hr {
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAADCAYAAABfwxXFAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA4JpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTExIDc5LjE1ODMyNSwgMjAxNS8wOS8xMC0wMToxMDoyMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDowMzgwMTE3NDA3MjA2ODExOTEwOUZCOTdDNTY3RDgzMCIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo5NzJENTk5NjlDQkYxMUU2ODlCQUM5QjIyNzEyOTFBRiIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo5NzJENTk5NTlDQkYxMUU2ODlCQUM5QjIyNzEyOTFBRiIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgMjAxNSAoTWFjaW50b3NoKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOmM1Nzc2OTIxLTVmNWYtNDdiNi1iMzk1LTdhYTMyMDE4MDA0YiIgc3RSZWY6ZG9jdW1lbnRJRD0iYWRvYmU6ZG9jaWQ6cGhvdG9zaG9wOmFlNTYzNWJiLWU1MjYtMTE3OS1iNmE1LWFlMTEyMDI2NTEwMCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pr+UdRwAAAA0SURBVHjaYrxx48ZSBgYGGyB2AOL7DAjAxAQkooBYDoidGFCBHEhyGRA/AeJ9aJKPAAIMAMpHCJImEbQgAAAAAElFTkSuQmCC);
            background-repeat: repeat-x;
            background-size: 3.5px 1.5px;
            border: none;
            height: 3px;
            margin:0 16px;
        }
    }

    
    .drawer-transition-enter {
		max-height: 0;
		opacity: 0.01;
        overflow: hidden;
        visibility: visible;

		&.drawer-transition-enter-active {
			max-height: 100vh;
			opacity: 1;
            transition: opacity 400ms ease-in-out 100ms, max-height 500ms ease-in-out;
            visibility: visible;
        }
        &.drawer-transition-enter-done {
		//	max-height: 100vh;
			opacity: 1;
            transition: opacity 400ms ease-in-out 100ms, max-height 500ms ease-in-out;
            visibility: visible;
		}
    }
    .drawer-transition-enter-done {
       // max-height: 100vh;
        opacity: 1;
        transition: opacity 400ms ease-in-out 100ms, max-height 500ms ease-in-out;
        visibility: visible;
        padding-bottom: 12px;
    }

	.drawer-transition-exit {
		max-height: 100vh;
		opacity: 1;
		overflow: hidden;

       
	}
    .drawer-transition-exit-active {
        max-height: 0;
        opacity: 0.01;
        transition: max-height 380ms ease-in-out, opacity 400ms ease-in-out;
        //transition: all .480ms ease-in-out;
    }
    .drawer-transition-exit-done {
        max-height: 0;
        opacity: 0.01;
        display: none;
       // transition: max-height 480ms ease-in-out, opacity 100ms ease-in-out;
    }

    
}

