@import '../../assets/scss/partials/vars';

html.more-menu-show {
    overflow: hidden;
    touch-action: none;
    -ms-touch-action: none;
    .more-menu-wrap.more-menu-show {
        height:94vh;
        @media(min-width: $screen-md){
            height: calc(100vh - 92px);
        }
    }
}

.fade-enter {
    opacity: 0;
    background-color:$aw-orange;
    height:100%;
    transition: opacity 0.5s .5s;
}

.fade-enter-active {
    background-color:$aw-orange;
    opacity: 1;
    height: 100%;
   transition: opacity .75s;
}

.fade-enter-done{
    opacity: 1;
    height: 100%;
    transition: opacity 0.3s;
}


.fade-exit {
    opacity:1;

     height:100%;
     transition: opacity 0.5s 0.5s;
}

.fade-exit-active {
    opacity:0;
    transition: opacity .2s;
}

.fade-exit-done {
    opacity:0;
    transition: opacity .5s 1s;
}


.more-menu-wrap {
    z-index: 10;
    position: absolute;
   // top: 16.5vw;
   top: 14.5vw;
    left: 0;
    right: 0;
    bottom: 0;
    height: 0;
   // transition: all 0.25s ease-out;

    @media(min-width: 420px){
        top: 14.5vw;
    }

    @media(min-width: $screen-sm){
        top: 12.5vw;
    }

    @media(min-width: $screen-md){
        top: 92px;
    }
    //transition-delay: 0.25s;
       
    &.more-menu-show{
      //  height: 94vh;
      height:0;
        transition: all 0.25s ease-out;
        @media(min-width: $screen-md){
      //      height: calc(100vh - 92px);
        }
    }

    nav.more-menu {
        width: 100%;
        height: 100%;
        background-color:$aw-orange;
        

        ul{
            height: 100%;
            padding: 0;
            list-style-type: none;

            li {
                height: 25%;
                width: 100%;
                background-position: center center;
                background-size: cover;

                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    box-sizing: border-box;
                    height: 100%; width: 100%;
                    margin: 0;
				    background-position: center center;
				    background-size: cover;
                    color: $white;
                    // text-transform: uppercase;
                    font-family: $AWSerifDisplay;
                    font-weight: 700;
                    font-size:2em;
                    text-decoration: none; 
                    transition: 1s;

                    &:hover {
                        background-color:$aw_orange;
                    }
                }

                i {
                    margin:0 0 0 10px;
                }
            }
        }
        
    }
}