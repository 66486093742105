@font-face {
	font-family: "AWSansRegular";
  	src: 
	url("../../fonts/AWFonts/AWSans-Regular.woff2") format("woff2"),
    url("../../fonts/AWFonts/AWSans-Regular.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "AWSansCondensedRegular";
  	src: 
	url("../../fonts/AWFonts/AWSans-CondensedRegular.woff2") format("woff2"),
    url("../../fonts/AWFonts/AWSans-CondensedRegular.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "AWSansDisplay";
  	src: 
	url("../../fonts/AWFonts/AWSans-Display.woff2") format("woff2"),
    url("../../fonts/AWFonts/AWSans-Display.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "AWSerifDisplay";
  	src: 
	url("../../fonts/AWFonts/AWSerif-Display.woff2") format("woff2"),
    url("../../fonts/AWFonts/AWSerif-Display.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "AWSerifRegular";
  	src: 
	url("../../fonts/AWFonts/AWSerif-Regular.woff2") format("woff2"),
    url("../../fonts/AWFonts/AWSerif-Regular.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'NexaRustScriptL-01';
	src: url("../../fonts/30E2ED_0_0.eot");
	src: url("../../fonts/30E2ED_0_0.eot?#iefix") format("embedded-opentype"), url("../../fonts/30E2ED_0_0.woff2") format("woff2"), url("../../fonts/30E2ED_0_0.woff") format("woff"), url("../../fonts/30E2ED_0_0.ttf") format("truetype");
	font-display: swap;
}

// @font-face {
// 	font-family: NexaRustScriptL-2;
// 	src: url('fonts/37B12E_3_0.eot');
// 	src: url('fonts/37B12E_3_0.eot?#iefix') format('embedded-opentype'),url('fonts/37B12E_3_0.woff2') format('woff2'),url('fonts/37B12E_3_0.woff') format('woff'),url('fonts/37B12E_3_0.ttf') format('truetype');}

// @font-face {
// 	font-family: Kent4FShadow;
// 	src: url("fonts/30E2ED_1_0.eot");
// 	src: url("fonts/30E2ED_1_0.eot?#iefix") format("embedded-opentype"), url("fonts/30E2ED_1_0.woff2") format("woff2"), url("fonts/30E2ED_1_0.woff") format("woff"), url("fonts/30E2ED_1_0.ttf") format("truetype");
// }

// @font-face {
// 	font-family: Kent4FPrintedShadowed;
// 	src: url("fonts/30E2ED_2_0.eot");
// 	src: url("fonts/30E2ED_2_0.eot?#iefix") format("embedded-opentype"), url("fonts/30E2ED_2_0.woff2") format("woff2"), url("fonts/30E2ED_2_0.woff") format("woff"), url("fonts/30E2ED_2_0.ttf") format("truetype");
// }

@font-face {
	font-family: 'Kent4FPrinted';
	src: 
		url("../../fonts/30E2ED_3_0.woff2") format("woff2"), 
		url("../../fonts/30E2ED_3_0.woff") format("woff"), 
		url("../../fonts/30E2ED_3_0.ttf") format("truetype"),
		url("../../fonts/30E2ED_3_0.eot"),
		url("../../fonts/30E2ED_3_0.eot?#iefix") format("embedded-opentype");
	font-display: swap;
}

@font-face {
	font-family: 'FFCartonnageStdRegular';
	src: 
		url('../../fonts/36F201_0_0.woff2') format('woff2'), 
		url('../../fonts/36F201_0_0.woff') format('woff'), 
		url('../../fonts/36F201_0_0.ttf') format('truetype'),
		url('../../fonts/36F201_0_0.eot'),
		url('../../fonts/36F201_0_0.eot?#iefix') format('embedded-opentype');
	font-display: swap;
}

@font-face {
	font-family: awico;
	src: 	
		url("../../fonts/awico.woff") format("woff"),
		url("../../fonts/awico.ttf") format("truetype"), 
		url("../../fonts/awico.eot"),
		url("../../fonts/awico.eot") format("embedded-opentype");	
	font-display: swap;
}
@font-face {
	font-family: awicomoonfont;
  	src:  
  		url('../../fonts/awicomoonfont.woff') format('woff2'),
	  	url('../../fonts/awicomoonfont.woff') format('woff'),
		url('../../fonts/awicomoonfont.ttf') format('truetype'), 
		url('../../fonts/awicomoonfont.eot') format('embedded-opentype');
	font-display: swap;
}