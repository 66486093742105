@import '../../assets/scss/partials/vars';

.location-bar {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1vw 4.5% 1vw;
    background-color: #fff;
    border-top: solid 3px $aw-orange;
    text-align: center;
    font-family: $AWSansRegular;
    font-size: 3.2vw;

    @media(min-width: $screen-xs){
      //  padding: 2vw 0px 1.8vw;
    }

    @media(min-width: $screen-md){
      justify-content: center;
      padding: 7px 0px 7px;
      font-size: 0.9rem;
    }


    a.more{
      display: flex;
      margin: 0 12px 0 0;
      // padding: 0px 8px;
    //  background: none;
      justify-content: center;
      align-items: center;
      outline: 0;
      color: #4b3327;
      height: 32px;
      padding-top:4px;
      padding-bottom:4px;
      border: 0;
      outline: 0;
      color: $aw-brown;
      text-align: right;
      font-family: $AWSansRegular;
      //font-size: 3.2vw;
      font-weight: 700;
      text-decoration: none;
      transition: padding-right 200ms, color 100ms ease-in-out;
      &.active {
        color:$aw-orange;
      }

      &:hover {
        color:$aw-orange;
      }
        @media(min-width:$screen-sm) {
          padding-top:10px;
          padding-bottom:10px;
        }

        @media(min-width: $screen-md){
          height:28px;
          margin: 0 16px 0;
          padding-top:0;
          padding-bottom:0;
        }


        i.aw.plus-inverted{
            margin: 0px  4px;
            font-size: 3.2vw;

            @media(min-width: $screen-md){
                font-size: 1.1rem;
            }
            
            
        }
    }
}