@import '../../../assets/scss/partials/vars';

.restaurant-locations-list {

    ul{
        width: 100%;
        background: #f5f5f5;

        li {
            width: 100%;
            border-top: 1px solid #f0f0f0;

            a.link-wrap {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: space-between;
                overflow: hidden;
                width: 96%;
                margin: 0 auto;
                padding: 6vw 0px;
                outline: none;
                border: 0;
                text-decoration: none;
                font-family: $GothamFont;
                white-space: nowrap;

                @media(min-width: $screen-md){
                    padding: 24px 0px;
                }

                &.active {
                    color: $aw-orange;

                    h4, i {
                        color: $aw-orange;
                    }
                }

                &:hover {
                   h4, .details .status.open, .distance, .details {
                    color: $aw-orange;
                   }
                }

                h4 {
                    position: relative;
                    display: flex;
                    width: 100%;
                    align-items: center;
                    margin-right: 8px;
                    color: $aw-brown;
                    font-family: $GothamFont;
                    font-size: 3.6vw;
                    font-weight: 600;
        
                    @media(min-width: $screen-md){
                        width: 80%;
                        font-size: 1.05rem;
                    }
    
                    i.aw{
                        font-size: 5vw;

                        @media(min-width: $screen-md){
                            font-size: 2rem;
                            line-height: 0.7;
                        }
                    }

                    span.fade {
                        z-index: 2;
                        position: absolute;
                        right: 20%;
                        top: 0;
                        display: block;
                        height: 2em;
                        width: 5em;
                        background: linear-gradient(90deg,hsla(0,0%,96%,0) 0,#f5f5f5);
                        content: "";
                        //opacity: .01;
                        transition: opacity 0ms linear;
                        @media(min-width:$screen-xl) {
                            right:-90px;
                        }
                    }
                }

                .details {
                    z-index: 6;
                    position: absolute;
                    right: 0;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    color: $aw-brown;
                    background: #F5F5F5;

                    span.fade {
                        z-index: 2;
                        position: absolute;
                        left: 0;
                        top: 0;
                        display: block;
                        height: 2em;
                        width: 3em;
                        background: linear-gradient(270deg,hsla(0,0%,96%,0) 0,#f5f5f5);
                        content: "";
                        //opacity: .01;
                        transition: opacity 0ms linear;
                        
                    }

                    span.driveIcon {
                        z-index: 4;
                        margin: 0 4px;
                    }

                    span.status {
                        z-index: 4;
                        color: $aw-brown;
                        font-size: 3.6vw;
                        font-weight: 600;
    
                        &.open, &.ouvert{
                            color: $aw-green;
                        }

                        @media(min-width: $screen-md){
                            font-size: 1.05rem;
                        }
                    }

                

                    span.distance {
                        z-index: 4;
                        margin: 0 1.2vw;
                        font-family: "Gotham A", "Gotham B";
                        font-size: 3.6vw;
                        font-weight: 400;
                        white-space: nowrap;

                        @media(min-width: $screen-md){
                            font-size: 1.05rem;
                        }
                    }

                    i.plus-inverted {
                        font-size: 5.4vw;
                        line-height: 0.7;

                        @media(min-width: $screen-md){
                            font-size: 1.5rem;
                        }
                    }

                }
                
        
            }
            
            
        }
    }

   
   
    
}