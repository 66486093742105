@import '../../../../assets/scss/partials/vars';

.hours-of-operation {
    color: $aw-brown;

    h3{
        padding: 3vw 0 6.5vw;
        font-family: $GothamFont;
        font-size: 3.6vw;
        font-weight: 600;

        @media(min-width: $screen-md){
            padding: 10px 0 30px;
            font-size: 1.05rem;
        }
        
    }

    table {
        width: 100%;
        padding-bottom: 3vw;
        color: $aw-brown;
        font-family: $GothamFont;
        font-size: 3.6vw;
        font-weight: 400;
        line-height: 1.5;

        @media(min-width: $screen-md){
            padding-bottom: 0px;
            font-size: 1.05rem;
        }

        th {
            font-weight: 400;
        }
        tr {
            text-align: left;
            padding: 2vw 0;

            &.today{
                font-weight: bold;
            }
        }
    }

    .drive-thru-24hrs {
        font-size:3.6vw;
        display: block;
        margin: 12px 0 0 0;
        padding: 0 0 10px;
        font-weight: bold;
        @media(min-width:$screen-md) {
            font-size: 1.05rem;
        }
    }
}