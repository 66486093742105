@import '../../../../assets/scss/partials/vars';

.amenities {
    padding: 3vw 0 4vw;

    @media(min-width: $screen-md){
        padding: 0 0 30px;
    }

    h3 {
        margin: 8px 0px 0px;
        padding: 0px 0 12px;
        color: $aw-brown;
        font-family: $GothamFont;
        font-size: 3.6vw;
        font-weight: 600;

        @media(min-width: $screen-md){
            font-size: 1.05rem;
            padding: 0px 0 12px;
        }
    }

    ul {
        list-style-type: none;
        li {
            color: $aw-brown;
            font-family: $GothamFont;
            font-size: 3.6vw;
            height:30px;
            width: 50%;
            display:inline-block;

            @media(min-width: $screen-md) {
                font-size: 0.95rem;
            }

            i.aw {
                font-size: 3.6vw;
                @media(min-width: $screen-md) {
                    font-size: 1.65rem;
                    line-height: 1.4;
                }
            }
        }
        svg {
            margin-right:10px;
        }
    }
}