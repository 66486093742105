i.aw {
	background-repeat: no-repeat;
	background-size: contain;
	border-radius: 3em;
	display: inline-block;
	font-family: "awicomoonfont";
	font-style: normal;
	font-weight: normal;
	height: 1em;
	line-height: 1;
	margin: 0;
	margin-bottom: -0.15em;
	overflow: visible;
	padding: 0.01em;
	position: relative;
	transition: background-color 100ms ease, color 100ms ease;
	width: 1em;

	$map: ("angled-arrow": "\e902", "angled-arrow-inverted": "\e901", "bullet": "\e906", "bullet-inverted": "\e905", "check": "\e907", "compass": "\e908", "compass-inverted": "\e91a", "facebook": "\e909", "flag": "\e90a", "gt": "\e90c", "gt-inverted": "\e90b", "instagram": "\e90d", "phone": "\e910", "pin": "\e911", "plus": "\e913", "plus-inverted": "\e912", "twitter": "\e900", "x": "\e917", "x-inverted": "\e916", "youtube": "\e918", "up-right-arrow": "\e915", "up-right-arrow-inverted": "\e914", "front-car": "\e919",  "keyboard_arrow_down": "\e313", "keyboard_arrow_right": "\e315", "search": "\e922", "tiktok": "\e903");
	@each $name, $code in $map {

		&.#{$name} span::before {
			content: "#{$code}";
			display: block;
			height: 100%;
			position: absolute;
			width: 100%;
		}
	}

	&.outline {
		background-image: url("i/outline.svg");
	}
}
