@import '../../assets/scss/partials/vars';

.explore-menu{
    display: block;
}

/* GENERAL STYLES ===================================
=================================================== */
.explore-menu-wrap {
    z-index: 1;
    overflow: hidden;
    position: absolute;
    width: 100%;
    text-align: center;
    color: $white;
    font-family: $baseFont;
    background-color: $aw_dbrown;

    @media(min-width: $screen-md){
        position: relative;
        overflow: visible;
    }

    .overview {
        font-weight: 500;
    }
    .menu-dagger {
        display: none;
        font-size:.6em;
    }
    .menu-dagger:lang(fr) {
        display: inline-block;
        font-size: .6em;
    }
    
}


 /* EXPLOREMENU MOBILE =====================================
========================================================= */
.explore-menu-wrap > .drawer{
   .title-wrap{
        background-color: $aw-brown;
    }
}
.explore-menu-wrap .drawer {
    overflow: hidden;
    color: #b9927e;
    background: $aw-dbrown;
    border: 0;
    outline: 0;
    transition: all 0.35s ease-in-out;

    &:last-child {
        border: none; 
    }

    .title-wrap {
        padding: 12px 5%;
        text-align: left;
        color: #b9927e;
        font-family: $baseFont;
        height: 30px;
        line-height: 0.7;
        
        @media(min-width:700px) {
          height:40px;
        }

       

        &.main-header{
            background-color: $aw_brown;
        }

        div.icon {
             
                transform: rotate(0deg); 
            
        }

        &:hover {
            div.icon {
                i { color: $white}
            }
        }
        
    }

    .options {
        display: block;
        overflow: hidden;
        width: 100%;
        margin: 0 auto;

        h3 {
            margin-top: 8px;
            margin-bottom: 6px;
            color: $white;
        }
    }

    &.open {
      //  height: 100vh;
        hr.first {
            display: none;
        }

        > .title-wrap {
            color: #b9927e; 

            div.icon {
                i { color: $white; 
                    transform: rotate(45deg)
                }
            }
        }
    }

   

    .list-items {
        display: block;
        padding-bottom: 200%;
        width: 90%;
        margin: 0 auto;
        text-align: left;
        color: $white;
        font-family: $baseFont;

        
        .title-wrap {
            padding: 12px 0;
            color: $white;
            background-color: $aw-dbrown;
        }

        a {
            color: #b9927e; 
            font-family: $baseFont;
            font-weight: 400;
            text-decoration: none;

            &:hover {
                color: $white;
            }

            &.active {
                color: $aw-orange;
            }

            i.aw {
                color: $white;
                &:hover {
                    color: $white;
                }
            }
        }

        li {
            overflow: hidden;
            display: block;
            background-image: linear-gradient( 90deg,#986f5a 33%,hsla(0,0%,100%,0) 0);  
            background-position: bottom;
            background-size: 3px 1px;
            background-repeat: repeat-x;

            a {
                display: block;
                width: 100%;
                padding: 12px 0 12px 20px;
                font-family: $baseFont;
                font-size: 3.6vw;

                &.overview {
                    padding: 14px 0;
                    color: $white;
                    font-family: $baseFont;
                    font-size: 3.45vw;
                    font-weight: 700;
                    text-decoration: none;

                    &.active {
                        color: $aw-orange;
                    }
                }
            }

           
        }

        
    }
}
  
  /* EXPLOREMENU DESKTOP ===================================
========================================================= */
.explore-nav-dt{
    display: block;
    overflow: hidden;
    background: $aw_brown;
    padding: 0;
    font-size:1rem;
    font-weight: bold;
  
    ul.our-food{
      margin-bottom:0;
    }
  
    span,button, a.btn-nav{
      display:inline-block;
      margin:0;
      padding:20px 32px;
      border:none;
      outline:none;
      background:none;
      font-size: 1rem;
      font-weight: 500;
      color: $white;
      text-decoration: none;
  
      &.active{
        color: $aw_orange;
         
      }
      &:hover{
        background-color: #36251C;
      }
  
    }
  
    a{
      font-family: $baseFont;
    }
  
    a.active i span, button.active i span{
      color: $aw_orange;
    }
  
    a.animal-welfare{
      display: block;
      font-size: 0.85rem;
      padding: 15px 0 15px 0px;
      border-top: dotted 1px #b9927e;
      margin-left: 18px;
      margin-right: 18px;
    }
  
    i span{
      display:block;
      padding:0 0 0 2px;
    }
  
    .dropdown{
      display:inline-block;
      cursor:pointer;
      &:hover{
        .dropdown-content{
          display: block;
        }
      }
      &:hover button{
        background-color: #36251C;
      }
    }
  
    .dropdown-content {
      position: absolute;
      display: none;
      z-index: 1;
      padding: 0px 10px 0;
      background-color: #36251C;
      color: $aw_lbrown;
      min-width: 160px;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      text-align: left;
      
      .category{
        color: $white;
        font-weight: 500;
      }
  
      a{
        color: $aw_lbrown;
        text-decoration: none;
        transition: color 300ms linear; 
  
        &.active{
          color: $aw_orange;
        }
        &:hover{
          color: $aw_orange;
        }
        // Hide the link to 'Plant-based nuggets in FR
        &.plant-based-nuggets:lang(fr){
          display: none;
        }
      }
  
    }
  
    .dropdown-content ul{
      display: inline-block;
      padding: 0px 18px 5px;
      text-align: left;
      font-size: 0.85rem;
      letter-spacing: 0.75px;
      vertical-align: top;
      list-style-type: none;   
    }
  
    .dropdown-content ul li{
      margin:12px 0;
    }
  
  }
  