section.strolling {
	display: block;
	list-style-type: none;
	margin: 0;
	padding: 0;

	> div {
		display: block;
		opacity: 0.01;

		&.animate {
			animation: moveUp 0.65s ease forwards;
			transform: translateY(200px);
		}

		@keyframes moveUp {

			0% {
				opacity: 0;
			}

			100% {
				opacity: 1;
				transform: translateY(0);
			}
		}
	}
}
