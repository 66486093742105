@import '../../assets/scss/partials/vars';

.blockwrap.header-wrap{
    background: $white;
    border-top: 1px solid#f0f0f0;
    transition: all 0.15s ease-in-out;
    overflow: visible;
    
    &.more-menu-show {
        margin-top: -10vw;
        overflow: visible;
        //position: ;
        //top: 0;

        @media(min-width: $screen-sm){
            //margin-top: -12vw;
        }

        @media(min-width: $screen-md){
            margin-top: -44px;
        }

    }

  
}
