@import '../../../assets/scss/partials/vars';


.social {
	margin-top: 16px;
	padding-bottom: 8vw;
	padding-top: 8vw;
    text-align: center;
    
    @media(min-width: $screen-md){
        margin: 0 auto;
        padding: 65px 0 45px;
    }

	a {
         
        display: inline-block;
		font-size: 8.4vw;
		margin-left: 2vw;
        margin-right: 2vw;
        
        @media(min-width: $screen-md){
            height: 52px;
            width: 52px;
            margin: 0 11px;
            font-size: 3rem;
        }

		i.aw {
            height: 10vw;
            width: 10vw;
            font-size: 10vw;
            padding: 0;
			background-color: $aw_orange;
            border-radius: 5vw;
            color: #fff;
            line-height: 1;
            transition: background-color 100ms ease-in-out;
            
            @media(min-width: $screen-md){
                height: 52px;
                width: 52px;
                line-height: 1.05;
                font-size: 3.15rem;
            }
            &.tiktok span::before {
                font-size: .45em;
                margin-top: .6em;
            }
        }

		&:hover {

			i.aw {
				background-color: #ff7a19;
			}
		}
	}
}
