@import '../../../assets/scss/partials/vars';

.map-panel {
    width: 100%;
    
    @media(min-width: $screen-md){
        height: 100%;
        width: 60%;
    }

    .map-wrap {
        display: block;
        min-height: 54.5vw;
        height: 100%;
        background: $aw-orange;
    
    
        @media(min-width: $screen-md){
            min-height: calc(100vh - 140px);
            height: 100%;
            width: 100%
        }
    
        &.loading {
            min-height: unset;
            height: 0px;
            visibility: hidden;
            overflow: hidden;
        }
    }

}
