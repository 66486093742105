@import '../../assets/scss/partials/vars';

.locations {
    
    overflow: hidden;

    .locations-map-wrapper {
        position: relative;
        display: flex;

    }

    .info-panel{
        overflow: hidden;
        overflow-y: scroll;
        background: $aw-orange;
        display: inline-block;
        width: 100%;
        height: 100%;
        max-height: 100vh;


        @media(min-width: $screen-md){
            display: inline-block;
            width: 40%;
            height: calc(100vh - 140px);
        }

        .intro-copy {
            display: block;
            //max-height: 50vh;
            max-width: 75vw;
            margin: 0 auto;
            padding-top: 7.5vw;
            padding-left: 20px;
            padding-right: 20px;
            //background: $aw-orange;
            text-align: center;
            color: rgb(255, 255, 255);
            font-family: $GothamFont;
            font-size: 3.6vw;
            font-weight: 400;
            letter-spacing: 0.018vw;
            line-height: 6.02vw;

            &.close {
                display: none;
            }
    
    
            @media(min-width: $screen-md){
                padding-top: 10px;
                font-size: 0.85rem;
                letter-spacing: 0.03rem;
                line-height: 1.4;
            }
    
            h1{
                font-family: $KentPrintFont;
                text-transform: uppercase;

                @media(min-width: $screen-md){
                    font-size: 2.39rem;
                    //letter-spacing: -0.04rem;
                }
            }

            p{
                font-family: $GothamFont;
                font-size: 3.6vw;

                @media(min-width: $screen-md){
                    font-size: 0.91rem;
                }
            }

            a {
                color: $white;
            }
        }

    }

    .mobile-order-banner {
        margin-top: 80px;
     //   margin-bottom: 80px;
    }
    
}