@import '../../../../../../assets/scss/partials/vars';

.hours-icon {

    display:inline-block;
    vertical-align: middle;
    svg {
        width:20px;
        height:20px;
    }
}