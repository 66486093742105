@import "_vars.scss";

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
}
  
html {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
    overflow-x: hidden;
}
*
{
    margin:0px;
    padding:0px;
}

// Make 100% height of browser window
body, #root {
    height: 100%;
    font-family: $baseFont;
    font-display: swap;
    font-size: 16px;
    color:$aw-brown;
   /*  position: relative; */
}

// General wrapper for site
.wrapper{
    display: block;
    min-height: 100%;
    //overflow: hidden;
}
// General blockwrap, wrapper for full width containers, always 100% width
.blockwrap {
    display:block;
    position: relative;
    overflow:hidden;
    width: 100%;
    text-align: center;
}
// General content container
.container {
    display: block;
    position: relative;
    // $screen-md = px;
    width: 100%;
    max-width: $screen-lg; // 1024px
    margin: 0 auto;
    text-align: center;

    @media(min-width: $screen-xl){ // 1200px
        max-width: $screen-xl;
    }
}

.content {
    display: block;
    margin: 0 auto;
    padding: 0 15px 0 15px;
    text-align: center;

    @media(min-width: $screen-md){
        padding: 0 20px 0 20px;
    }

    img {
        max-width: 100%;
    }
}

h1,h2,h3,h4,h5 {
     
    font-family: $baseFont, Arial, Helvetica, sans-serif;
}


a {
    transition: 0.4s ease  all;
    outline: none;
    border: none;
    color:$aw-orange;
}
a:hover {

}
a:visited {
     
}
/* :link { color: #0000EE; } */
/* :visited { color: $raft-green; } */

/* CSS
/* HELPERS */

.show {
    display: block;
}
.hide {
    display: none!important;
}
.text-left {
    text-align: left;
}
.text-right {
    text-align: right;
}
.side-half {
    display: inline-block;
    width:49%;
}

.not-visible {
  visibility: hidden !important;
  opacity: 0 !important;
  transition: opacity 3.25s ease-in-out;
}

br.mobile {
    display: block;
    @media (min-width: $screen-md){
        display: none;
    }
}

br.desktop {
    display: none;
    @media (min-width: $screen-md){
        display: block;
    }
}

html:lang(en) {
   
} 
html:lang(fr) {
    
} 

.bounce {
    animation: bounce 2s infinite;
}

@keyframes bounce {

	0%, 20%, 50%, 80%, 100% {
		transform: translateY(0);
	}

	40% {
		transform: translateY(-1em);
	}

	60% {
		transform: translateY(-0.5em);
	}
}

 /*
    *  Custom scrollbar for webkit browsers
    */
    // body::-webkit-scrollbar-track{
    //     //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    //     background-color: $aw-light-grey;
    // }
    
    // body::-webkit-scrollbar{
    //     width: 7px;
    //     background-color: $aw-light-grey;
    // }
    
    // body::-webkit-scrollbar-thumb{
    //     background-color: $aw_orange;
    //     border-radius: 10px;
    //     //border: 2px solid #555555;
    //}


    // .options::-webkit-scrollbar-track{
    //     //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    //     background-color: $aw-light-grey;
    // }
    
    // .options::-webkit-scrollbar{
    //     width: 7px;
    //     height: 7px;
    //     background-color: $aw-light-grey;
    // }
    // .options::-webkit-scrollbar-thumb{
    //     background-color: $aw_orange;
    //     border-radius: 10px;
    //     //border: 2px solid #555555;
    // }