@import './vars';

/* DEFAULT STYLES */

h1,h2,h3,h4,h5 {
  font-family: $AWSansDisplay, Arial, Helvetica, sans-serif;
  font-weight: 700;
  letter-spacing: 0.04rem;
}

h2 {
 font-size:5.5vw;
 @media(min-width:$screen-md) {
   font-size:1.5rem;
 } 
}

h3 {
  font-size:3.6vw;
  @media(min-width:$screen-md) {
    font-size:1.1rem;
  }
}

// p {
//   margin: 20px 0;
//   font-size:3.6vw;
  
//   @media(min-width:$screen-md) {
//     font-size:.95em;
//   }
// }

.bg-orange {
  background-color:$aw-orange;
  margin-bottom:20px;
}

.copy {
  line-height:1.4;
}

.white {
  color:#fff;
}