/* VARS - A&W CORPORATE WEBSITE ==========================
======================================================= */
$white: #FFFFFF;
$black: #000000;

// General
$aw-orange: rgba(255, 98, 24, 1); // #ff6218;
$aw-orange-dark: rgba(224, 82, 17, 1); // #e05211;
$aw-orange-rollover: #ff7a19;
//$aw-brown: rgba(75, 51, 39, 1); // #4b3327;
$aw-green: #490;
$aw-brown: rgb(75, 51, 39); // #4b3327;
$aw_dbrown: #36251c;
$aw-dark-brown: rgba(54, 37, 28, 1); // #36251c;
$aw_lbrown: #b9927e;
$aw-light-brown: rgba(185, 146, 126, 1); // #b9927e;
$aw-yellow: rgba(255, 228, 0, 1); // #ffe400
$aw-light-grey: rgba(242, 242, 242, 1); //#f2f2f2
$copy-grey:  rgba(103, 103, 103, 1); //#676767;
$aw-red: #da291c;
 
// FONTS ===============================
$baseFont: "AWSerifRegular", serif;
//$baseFont: "AWSerifDisplay", serif;
// A&W FONTS ===============================
$AWSerifDisplay : "AWSerifDisplay", serif;
$AWSerifRegular : "AWSerifRegular", serif;
$AWSansRegular : "AWSansRegular", sans-serif;
$AWSansCondensedRegular : "AWSansCondensedRegular", sans-serif;
$AWSansDisplay: "AWSansDisplay", sans-serif;


//$GothamFont: "Gotham A", "Gotham B";
//$NexaRustScript: 'NexaRustScriptL-01', sans-serif;
//$NexaRustScriptL2: 'NexaRustScriptL-2', sans-serif;
//$Cartonage: 'FFCartonnageStdRegular', sans-serif;
//$KentPrintFont: 'Kent4FPrinted', sans-serif;


$GothamFont: $baseFont; // Just in case
$KentPrintFont: $AWSansDisplay;
$NexaRustScript: $AWSansDisplay;
$NexaRustScriptL2: $AWSansDisplay;
$Cartonage: $AWSansDisplay;

$awicoFont: awico, sans-serif;
$awicoMoonFont: awicomoonfont;

// Container breakpoint sizes

// smartphones (portrait view)
$screen-xs: 374px;

// Small tablets and large smartphones (landscape view)
$screen-sm: 576px;

// Small tablets (portrait view)
$screen-md: 850px;

// Tablets and small desktops
$screen-lg: 1024px;

// Large tablets and desktops
$screen-xl: 1200px;

// Super large
$screen-max: 1600px;
