@import '../../../assets/scss/partials/vars';

nav.nav-menu {
    overflow: hidden;
    display: block;
    max-width: 100%;
    margin: 2px auto 2vw;
    padding-top: 0.69vw;
    
    @media(min-width: $screen-md){
        max-width: 730px;
        margin: 2px auto 4px;
        padding-top: 3px;
    }
   

    ul {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        list-style-type: none;
        padding: 0 0 2vw 0;

        @media(min-width: $screen-md){
            margin: 0 auto 8px;
            padding: 0px 0 4px 0;
        }

        li:first-child {
           a {
               padding: 0;
               margin: 0;
           }
        }

        li {
            font-family: $AWSerifDisplay;
           

            a, button{
                display: block;
                padding: 2.7vw 1.75vw 3px;
                margin: 0;
                outline: 0;
                border: 0;
                background-repeat: repeat-y;
                text-align: center;
                color: $aw-brown;
                font-family: $AWSerifDisplay;
                font-size: 4.4vw;
                font-weight: 700;
                text-decoration: none;
                cursor: pointer;
                transition: color 0.35s linear;
                &:lang(fr) {
                    font-size:4vw;
                  //  padding: 2.7vw 1.35vw 3px;
                }
               

                @media(min-width: $screen-xs){
                    font-size: 4.4vw;
                    i {
                        font-size:4vw;
                    }
                    //padding: 16px 2.5vw 7px;
                    // &:lang(fr) {
                    //     font-size: 1.2832rem;
                    //  }
                    //  i {
                    //     font-size:3.5vw;
                    // }
                }

                @media(min-width: $screen-md){
                   
                    margin-top: 8px;
                    padding: 20px 14px;
                    //font-size: 16.8px
                    font-size: 1.5rem;
                    font-weight: 700;
                       &:lang(fr) {
                        font-size: 1.2832rem;
                     }
                     i {
                        font-size:1.15rem;
                     }
                   
                }

                i {
                    margin-left:4px;
                }

                &.active{
                    color: $aw-orange;
                }
                &:hover {
                    color: $aw-orange;
                }

                
            }

            button.drop-menu-toggle {
                display: block;
                background: none;
                width:40px;
                &:lang(fr) {
                    width:30px;
                }

                @media(min-width: $screen-md){
                    display: flex;
                    align-items: center;
                    width:auto;
                }

                .icon-menu-wrap{
                    display: inline-block;
                    margin: 0 0 0 -10px;
                    height:.45em;
                    @media(min-width: $screen-md){
                        heighT:auto;
                        margin: -2px 0 0 6px; 
                    }


                    span, span::before, span::after {
                        background-color: #4b3327;
                        border-radius: 0.5em;
                        content: "";
                        display: block;
                        height: 2px;
                        margin-bottom: 0.1em;
                        position: absolute;
                        transition: transform 500ms ease-in-out, background-color 500ms ease-in-out;
                        width: 1em;
                    }
                
                    span {
                        font-size: 1em;
                    
                        @media(min-width:$screen-md) {
                            font-size: 1rem;
                        }
                        
                        //margin-top: 0.67em;
                    }
                
                    span::before {
                        margin-top: -0.4em;
                    }
                
                    span::after {
                        margin-top: 0.45em;
                    }
                
                    &.active span {
                        background-color: transparent;
                
                        &::before {
                            background-color: #ff6218;
                            margin-top: 0;
                            transform: rotate(45deg);
                        }
                
                        &::after {
                            background-color: #ff6218;
                            margin-top: 0;
                            transform: rotate(-45deg);
                        }
                    }
                }
                
            }
        }

    }


    // li button.drop-menu-toggle {
    //    display: block;
    //    min-height: 40px;
    //    min-width: 30px;
      
    //    outline: 0;
    // border: 0;
    // background: none;


    //     span, span::before, span::after {
    //         background-color: #4b3327;
    //         border-radius: 0.5em;
    //         content: "";
    //         display: block;
    //         height: 0.18em;
    //         margin-bottom: 0.1em;
    //         position: absolute;
    //         transition: transform 500ms ease-in-out, background-color 500ms ease-in-out;
    //         width: 1em;
    //     }
    
    //     span {
    //         margin-top: 0.67em;
    //     }
    
    //     span::before {
    //         margin-top: -0.4em;
    //     }
    
    //     span::after {
    //         margin-top: 0.45em;
    //     }
    
    //     &.active span {
    //         background-color: transparent;
    
    //         &::before {
    //             background-color: #ff6218;
    //             margin-top: 0;
    //             transform: rotate(45deg);
    //         }
    
    //         &::after {
    //             background-color: #ff6218;
    //             margin-top: 0;
    //             transform: rotate(-45deg);
    //         }
    //     }
    // }
    
    .desktop .nav-toggle {
        padding-left: 0.1em;
    }
    
    a:hover .drop-menu-toggle {
    
        span, span::before, span::after {
            background-color: #ff6319;
        }
    
        &.active span {
            background-color: transparent;
        }
    }
    



}


